import { useTranslation } from "react-i18next";

const AboutUs = () => {

  const { t } = useTranslation();

  return (
    <section className="container my-5" id="about-us">
      <div className="text-center">
        <h1>{t('about_us')}</h1>
        <h5 className="mt-5 mb-4">{t('about_us_text')}</h5>
        <h4>{t('about_us_subtitle')}</h4>
      </div>
      <div className="row mt-5">
        <div className="col-md-3">
          <div className="row">

            <div className="col-3">
              <img src="/images/about-us/car.png" alt="about-us" className="image" />
            </div>
            <div className="col-9">
              <h4>{t('luxury_cars_title')}</h4>
              <p>{t('luxury_cars_text')}</p>
            </div>
            <div className="col-3 mt-5">
              <img src="/images/about-us/wifi.png" alt="about-us" className="image" />
            </div>
            <div className="col-9 mt-5">
              <h4>WiFi</h4>
              <p>{t('wifi_text')}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 mb-md-0">
          <img src="/images/about-us/about-us.png" alt="about-us" className="image" />
        </div>
        <div className="col-md-3">
          <div className="row">
            <div className="col-9 text-end">
              <h4>{t('bottle_water_title')}</h4>
              <p>{t('bottle_water_text')}</p>
            </div>
            <div className="col-3">
              <img src="/images/about-us/bottle.png" alt="about-us" className="image" />
            </div>
            <div className="col-9 mt-5 text-end">
              <h4>{t('charger_title')}</h4>
              <p>{t('charger_text')}</p>
            </div>
            <div className="col-3 mt-5">
              <img src="/images/about-us/charger.png" alt="about-us" className="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutUs;