const Footer = () => {
  return (
    <section className="bg-black text-center py-2" id="contact">
      <div className="container">
        <img src="/images/logo/logo-white.png" alt="logo" className="logo my-4 mb-5 mb-md-4" />
        <div className="row ps-5 ps-md-0">
          <div className="col-md-4 d-flex align-content-center">
            <i className="fa fa-envelope icon-footer mr-2"></i>
            <p><a className="text-decoration-none text-white ms-3" href="mailto:calke08@gmail.com">calke08@gmail.com</a></p>
          </div>
          <div className="col-md-4 d-flex align-content-center justify-content-md-center py-3 py-md-0">
            <i className="fa fa-phone icon-footer mr-2"></i>
            <p><a className="text-decoration-none text-white ms-3" href="tel:+38163222543">+381 63 222 543</a></p>
          </div>
          <div className="col-md-4 d-flex align-content-center justify-content-md-end">
            <i className="fa fa-clock icon-footer mr-2"></i>
            <p className="text-white ms-3">0-24</p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Footer