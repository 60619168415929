import { useTranslation } from "react-i18next";
import PriceData from "../data/PriceData.json";

const Price = () => {

  const { t } = useTranslation();

  return (
    <section className="py-5">
      <div className="container text-center">
        <div className="bb-yellow pt-3">
          <h1 className="font-yellow">{t('price_page_title')}</h1>
        </div>
        <p className="my-5">{t('price_page_text')}</p>


        <div className="row justify-content-center">
          {PriceData.map((item) => (
            <div className="col-md-6 col-lg-4 mb-3" key={item.id}>
              <div className="d-flex flex-column single-service-container m-2 my-3 bg-white shadow-lg rounded-3">
                <div className="yellow-bg rounded-top-3 text-white height-price-header pb-3 px-1">
                  <h4 className="pt-4">{t(item.title_price)}</h4>
                  <h5>{t(item.subtitle_price)}</h5>
                </div>
                <div className="pb-3 px-3 align-content-top">
                  <h2>{item.price}</h2>
                  <ul className="text-start detail-price" dangerouslySetInnerHTML={{ __html: t(item.detail_price) }}></ul>
                </div>
              </div>
            </div>
          ))}
        </div>


        <p className="my-5">{t('price_page_text1')}</p>
      </div>
    </section>
  )
}
export default Price;