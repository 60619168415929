import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Counter = () => {

  const { t } = useTranslation();

  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [startCounting, setStartCounting] = useState(false);

  const maxCount1 = 50;
  const maxCount2 = 1500;
  const duration = 3000;
  const intervalTime = 50;

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('target-element');
      if (element && !startCounting) {
        const elementTop = element.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (elementTop < viewportHeight) {
          setStartCounting(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [startCounting]);

  useEffect(() => {
    if (startCounting) {
      const step1 = maxCount1 / (duration / intervalTime);
      const step2 = maxCount2 / (duration / intervalTime);

      const timer = setInterval(() => {
        setCounter1(prev => (prev < maxCount1 ? prev + step1 : maxCount1));
        setCounter2(prev => (prev < maxCount2 ? prev + step2 : maxCount2));
      }, intervalTime);

      return () => clearInterval(timer);
    }
  }, [startCounting]);

  return (
    <>
      <section className="counter-bg py-3">
        <div className="container">
          <div className="row text-center">
            <div id="target-element" style={{ height: '1px' }}>
            </div>
            <div className="col-md-6 mb-5 mb-md-0">
              <h3>{t('counter_over')}</h3>
              <div className="counter">{Math.round(counter1)}</div>
              <h3>{t('counter_locations')}</h3>
            </div>
            <div className="col-md-6 mt-5 mt-md-0">
              <h3>{t('counter_over')}</h3>
              <div className="counter">{Math.round(counter2)}</div>
              <h3>{t('counter_clients')}</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;
