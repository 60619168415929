import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';
import i18n from "i18next";
import { useTranslation } from "react-i18next"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { t } = useTranslation();

  const changeLanguage = (lang) => {
    if (lang === "eng") {
      i18n.changeLanguage("eng");
    }
    else {
      i18n.changeLanguage("sr");
    }
  }

  return (
    <>
      <section className="container d-none d-md-block" id="home">
        <div className="row text-center mt-4">
          <Link className="col-4 navbar-brand align-content-center" to="/">
            <div>
              <img src="images/logo/logo.png" alt="Logo" className="logo" />
            </div>
          </Link>
          <div className="col-4 align-content-center d-none d-md-block">
            <div className="row justify-content-center">
              <div className="col-4 text-end">
                <img src="images/icon/icon-phone.png" alt="phone" className="icon-header" />
              </div>
              <div className="col-2">
                <img src="images/icon/icon-whatsapp.png" alt="phone" className="icon-header" />
              </div>
              <div className="col-4 text-start">
                <img src="images/icon/icon-viber.png" alt="phone" className="icon-header" />
              </div>
            </div>
            <h4 className="mt-2 mb-0">+381 63 222 543</h4>
          </div>
          <div className="col-4 align-content-center d-none d-md-block">
            <div className="">
              <img src="images/icon/icon-mail.png" alt="phone" className="icon-header" />
            </div>
            <h4 className="mt-2 mb-0">calke08@gmail.com</h4>
          </div>
        </div>
        <div className={`container navigation mt-3 shadow ${isFixed ? 'fixed-top' : ''}`}>
          <nav className="navbar navbar-expand-lg navbar-light justify-content-center text-center">
            <div>
              <div className="ml-auto d-lg-none fade-in-element">
                <button className="btn-menu-mobile p-0" type="button" onClick={toggleMenu}>
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className={`collapse navbar-collapse ${isOpen ? 'show fade-in-element' : ''}`} id="navbarNav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item me-md-3">
                    <NavLink className="nav-link" smooth to="/#home" onClick={closeMenu}>{t('home')}</NavLink>
                  </li>
                  <li className="nav-item me-md-3">
                    <NavLink className="nav-link" smooth to="/#service" onClick={closeMenu}>{t('service')}</NavLink>
                  </li>
                  <li className="nav-item me-md-3">
                    <NavLink className="nav-link" smooth to="/#about-us" onClick={closeMenu}>{t('about_us')}</NavLink>
                  </li>
                  <li className="nav-item me-md-3">
                    <NavLink className="nav-link" smooth to="/#price" onClick={closeMenu}>{t('price')}</NavLink>
                  </li>
                  <li className="nav-item me-md-3">
                    <NavLink className="nav-link" smooth to="/#contact" onClick={closeMenu}>{t('contact')}</NavLink>
                  </li>
                  <li className="ms-0 ms-lg-5 mt-1">
                    <img src={`/images/language/english.png`} className={`pe-2 ${i18n.language === 'eng' ? 'opacity-50' : ''}`} alt="" onClick={() => changeLanguage('eng')} role="button"></img>
                    <img src={`/images/language/serbian.png`} className={i18n.language === 'sr' ? 'opacity-50' : ''} alt="" onClick={() => changeLanguage('ser')} role="button"></img>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <nav className="navbar navbar-expand-lg navbar-light fixed-top yellow-bg shadow d-block d-md-none">
        <div className="container">
          <NavLink className="navbar-brand" to="/#home-m">
            <img src={`/images/logo/logo.png`} alt="Logo" className="logo" />
          </NavLink>
          <div className="ml-auto d-lg-none fade-in-element">
            <a className="text-decoration-none" href="tel:+38163222543"><img src="images/icon/icon-phone.png" alt="phone" className="icon-header me-3" /></a>
            <button className="btn-menu-mobile custom-toggler p-0" type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon navbar-icon-size"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse mt-3 ${isOpen ? 'show fade-in-element' : ''}`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item me-md-3">
                <NavLink className="nav-link navigation-font" smooth to="/#home-m" onClick={closeMenu}>{t('home')}</NavLink>
              </li>
              <li className="nav-item me-md-3">
                <NavLink className="nav-link navigation-font" smooth to="/#service" onClick={closeMenu}>{t('service')}</NavLink>
              </li>
              <li className="nav-item me-md-3">
                <NavLink className="nav-link navigation-font" smooth to="/#about-us" onClick={closeMenu}>{t('about_us')}</NavLink>
              </li>
              <li className="nav-item me-md-3">
                <NavLink className="nav-link navigation-font" smooth to="/#price" onClick={closeMenu}>{t('price')}</NavLink>
              </li>
              <li className="nav-item me-md-3">
                <NavLink className="nav-link navigation-font" smooth to="/#contact" onClick={closeMenu}>{t('contact')}</NavLink>
              </li>
              <li className="ms-0 ms-lg-5 mt-1">
                <img src={`/images/language/english.png`} className={`pe-2 ${i18n.language === 'eng' ? 'opacity-50' : ''}`} alt="" onClick={() => changeLanguage('eng')} role="button"></img>
                <img src={`/images/language/serbian.png`} className={i18n.language === 'sr' ? 'opacity-50' : ''} alt="" onClick={() => changeLanguage('ser')} role="button"></img>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;