import Slider from "react-slick"
import SliderHomeData from "../data/SliderHome.json"
import { useTranslation } from "react-i18next"

const SliderHome = () => {
  const { t } = useTranslation();
  return (
    <section className="" id="home-m">
      <div className="w-100">
        <Slider
          speed={2000}
          autoplaySpeed={5000}
          swipeToSlide={true}
          arrows={false}
          centerMode={false}
          focusOnSelect={true}
          fade={true}
          autoplay={true}
          slidesToShow={1}
        >
          {SliderHomeData.map((item) => (
            <div key={item.id}>
              <div className="slider-image" style={{ backgroundImage: `url(/images/slider-home/${item.image}.webp)`, }}>
                <div className="container slider-content">
                    <h2 className="slider-text">{t('slider_title')}</h2>
                    <h3 className="text-center text-white mt-3">{t('slider_subtitle')}</h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
export default SliderHome