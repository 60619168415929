import { useTranslation } from "react-i18next";
import PriceData from "../data/PriceData.json";
import React, { useState, useEffect } from 'react';

const Price = () => {

  const { t } = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 991);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="service py-5" id="price">
      <div className="container text-center">
        <h1 className="text-white mb-4">{t('price_title')}</h1>
        <div className="row justify-content-center g-0">
          {PriceData.map((item) => (
            <div className="col-md-6 col-lg-4 mb-3 px-1 px-lg-0" key={item.id}>
              <div className={`d-flex flex-column single-service-container pt-4 bg-white ${!isSmallScreen && item.class_div ? item.class_div : ''}`}>
                <div className="image-container">
                  <i className={`${item.icon} icon ${item['class-icon']}`}></i>
                </div>
                <h4 className="py-5 h-100">{t(item.title)}</h4>
                <div className="yellow-bg">
                  <h2 className="py-4">{t(item.price)}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Price;