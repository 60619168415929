import ContactForm from "./ContactForm";
import ServiceData from "../data/ServiceData.json";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();
  return (
    <section className="service py-0 py-md-5">
      <ContactForm />
      <div className="container text-center py-5 py-md-0" id="service">
        <div className="service-inner align-content-center">
          <h2 className="service-text">{t('service_title')}</h2>
        </div>
        <h5>{t('service_text1')}</h5>
        <h5>{t('service_text2')}</h5>
        <div className="text-center my-5">
          <a href="tel:+38163222543" className="text-decoration-none link-style-button">+381 63 222 543</a>
        </div>
        <div className="row justify-content-center">
          {ServiceData.map((item) => (
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0" key={item.id}>
              <div className="d-flex flex-column single-service-container m-2 my-3 px-3 py-4 bg-white ">
                <div className="image-container">
                  <img src={`/images/service/${item.id}.webp`} alt={item.title} className="image" />
                </div>
                <h4 className="pt-4 pb-5">{t(item.title)}</h4>
                <p>{t(item.description)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Service;