import Slider from "react-slick"
import CustomerData from "../data/CustomerData.json"
import { useTranslation } from "react-i18next";

const Customer = () => {

  const { t } = useTranslation();
  
  return (
    <section className="bg-grey py-5">
      <div className="container">
        <h2 className="text-center py-5">{t('customers_title')}</h2>
      </div>
      <div className="w-100">
        <Slider
          speed={2000}
          autoplaySpeed={8000}
          swipeToSlide={true}
          arrows={false}
          centerMode={false}
          focusOnSelect={true}
          // fade={true}
          autoplay={true}
          slidesToShow={1}
        >

          {CustomerData.map((item) => (
            <div key={item.id}>
              <div className="container">
                <div className="bg-white customer-container text-center mx-auto p-5">
                  <div>
                    <img src={`/images/customer/${item.id}.webp`} alt={item.name} className="mx-auto"/>
                  </div>
                  <h3 className="mt-3 font-yellow">{t(item.name)}</h3>
                  <p className="mt-3">{t(item.text)}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
export default Customer