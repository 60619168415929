import AboutUs from "../components/AboutUs";
import Counter from "../components/Counter";
import Customer from "../components/Customer";
import Price from "../components/Price";
import Service from "../components/Service";
import SliderHome from "../components/SliderHome";

const Home = () => {
  return (
    <>
        <SliderHome/>
        <Service/>
        <AboutUs/>
        <Counter/>
        <Price/>
        <Customer/>
    </>
  )
}
export default Home;