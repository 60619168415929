import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Price from './pages/Price';
import translationSR from "./locales/sr.json"
import translationEN from "./locales/en.json"
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import BackToTopButton from './components/BackToTopButton';

function App() {

  const resources = {
    sr: {
      translation: translationSR
    },
    eng: {
      translation: translationEN
    }
  };

  i18next
    .use(initReactI18next)
    .init({
      resources,
      lng: "sr",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

  return (
    <>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/about-us" element={<AboutUs />} />
      <Route path="/service" element={<Service />} />
      <Route path="/about-me" element={<AboutMe />} />
      <Route path="/price" element={<Price />} /> */}
      <Route path="/cenovnik" element={<Price />} />
    </Routes>
    <Footer />
    <BackToTopButton />
    </>
  );
}

export default App;
